<template>
  <el-radio-group

    class="center-block"
  >
    <el-col
      class="pz-large-margin"
    >
      <h3 class="text-center pz-large-margin pz-color-primary">
        {{ $t('forms_flujo.oferta_down_solicitud_default.label_1') }}
      </h3>
      <el-col
        v-for="(value, index) in myForm.montos_array"
        :key="index"
      >
          <input
                  :id="index"
                  v-model="myForm.otras_ofertas.final"
                  type="radio"
                  :value="index"
                  class="el-col el-col-4"
          >
          <label
                  :for="index"
                  class="pz-color-primary pz-font-size-lg el-col el-col-20"
          >
           <span class="pz-font-size-xl pz-color-secondary">
              {{ value.monto|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}
            </span>
            {{ $t('forms_flujo.oferta_down_solicitud_default.label_2') }}
            <span class="pz-font-size-xl pz-color-secondary">
              {{ value.cuota }}
            </span>
            {{ $t('forms_flujo.oferta_down_solicitud_default.label_3') }}
            <span class="pz-font-size-xl pz-color-secondary">
              {{ value.monto_cuota|currency(myForm.producto.moneda_prestamo.symbol,0,{spaceBetweenAmountAndSymbol:true }) }}
            </span>
          </label>
      </el-col>
    </el-col>
    <el-col
          class="pz-large-margin"
  >
      <h3 class="text-center pz-large-margin pz-color-primary">
          {{ $t('forms_flujo.oferta_down_solicitud_default.label_4') }}
      </h3>
      <el-col
              v-for="(value, index) in myForm.cuotas_array"
              :key="index+2"
      >
          <input
                  :id="index+2"
                  v-model="myForm.otras_ofertas.final"
                  type="radio"
                  :value="index+2"
                  class="el-col el-col-4"
          >
          <label
                  :for="index+2"
                  class="pz-color-primary pz-font-size-lg el-col el-col-20"
          >
          <span class="pz-font-size-xl pz-color-secondary">
            {{ value.monto|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}
          </span>
              {{ $t('forms_flujo.oferta_down_solicitud_default.label_2') }}
              <span class="pz-font-size-xl pz-color-secondary">
            {{ value.cuota }}
          </span>
              {{ $t('forms_flujo.oferta_down_solicitud_default.label_3') }}
              <span class="pz-font-size-xl pz-color-secondary">
            {{ value.monto_cuota|currency(myForm.producto.moneda_prestamo.symbol, 0 ,{ spaceBetweenAmountAndSymbol:true }) }}
          </span>
          </label>
      </el-col>
  </el-col>
  </el-radio-group>
</template>
<script>
    export default {
        name: 'PzOfertaDownSolicitudUy',
        inject: ['myForm'],
        data() {
            return {
                picked: 'One'
            }
        },
        mounted() {
        }
    }
</script>

