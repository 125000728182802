<template>
  <el-col :span="24">
    <pz-title>
      <span slot="title">
        {{ $t('forms_flujo.ofertas_default.title') }}
      </span>
      <span slot="sub_title">
        {{ $t('forms_flujo.ofertas_default.sub_title') }}
      </span>
    </pz-title>
    <el-row class="center-block">
      <comment :is="'pz-oferta-up-'+elOfertaView" />
    </el-row>
    <el-row
      v-if="down"
      class="center-block"
    >
      <el-col :span="24">
        <hr class="pz-dotted">
        <pz-title>
          <span slot="title">
            {{ $t('forms_flujo.ofertas_default.other_options') }}
          </span>
        </pz-title>
        <el-row>
          <el-col
            :xl="{span: 12, offset:6 }"
            :lg="{span: 12 ,offset:6}"
            :md="{span: 12 ,offset:6}"
            :sm="{span: 18 ,offset:3}"
            :xs="{span: 22 ,offset:1}"
          >
            <el-card>
              <el-col
                :span="24"
              >
                <el-form
                  :ref="reference"
                  :model="otras_ofertas"
                  status-icon
                  :rules="rules_otrasOfertas"
                  :label-position="'left'"
                  class="center-block "
                >
                  <el-form-item
                    prop="final"
                    :error="errors.get('final')"
                  >
                    <comment :is="'pz-oferta-down-'+elOfertaView" />
                  </el-form-item>
                  <el-row>
                    <el-col
                      :span="24"
                      class="text-center"
                    >
                      <pz-back-button
                        :back="handleBack"
                      />
                      <pz-forward-button :forward="setOferta" />
                    </el-col>
                  </el-row>
                </el-form>
              </el-col>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-col>
</template>

<script>
import {required} from '@/commons/utils/Rules';
import baseOfertas from "../baseOfertas";
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzTitle from "@/commons/titles/PzTitle";

export default {
    name: 'PzOffersUy',
    components: {PzTitle, PzForwardButton},
    mixins: [baseOfertas],
    data() {
        return {
            otras_ofertas: {final: ''},
            rules_otrasOfertas: {
                final: [required('')],
            }
        }
    },

    methods: {
        setOferta(original = false) {
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            if (original) {
                this.$emit('input',
                    {monto: this.best.monto, cuota: this.best.cantidad_cuota, original: 1});
                this.$emit('save');
            } else {
                let validation = this.handleSave(false);
                if (validation) {
                    let acepted='';
                    if (this.otras_ofertas.final > 1) {
                        var index = this.otras_ofertas.final - 2;
                        acepted = this.cuotas_array[index];
                    } else {
                        acepted = this.montos_array[this.otras_ofertas.final];
                    }
                    this.$emit('input', {monto: acepted.monto, cuota: acepted.cuota, original: 0});
                    this.$emit('save');
                }
            }
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        },
        handleBack() {
            return this.$router.go(-1);
        },
    }
}
</script>
