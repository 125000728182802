<template>
  <el-row>
    <el-col
      :xl="{span: 12, offset:6 }"
      :lg="{span: 12 ,offset:6}"
      :md="{span: 12 ,offset:6}"
      :sm="{span: 18 ,offset:3}"
      :xs="{span: 18 ,offset:3}"
    >
      <el-card class="center-block">
        <div class=" pz-large-margin">
          <h2 class="text-center  pz-font-size-xl pz-color-secondary">
            <span class="pz-color-primary">
              {{ myForm.best.monto|currency(myForm.producto.moneda_prestamo.symbol, 0) }}
            </span>
            {{ $t('forms_flujo.oferta_up_solicitud_default.label_1') }}
            <span class="pz-color-primary">
              {{ myForm.best.cantidad_cuota }}
            </span>
            {{ $t('forms_flujo.oferta_up_solicitud_default.label_2') }}
          </h2>
          <h2 class="text-center pz-font-size-xl pz-color-secondary">
            {{ $t('forms_flujo.oferta_up_solicitud_default.label_3') }}
            <span class="pz-color-primary">
              {{ myForm.best.monto_cuota|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}
            </span>
            {{ myForm.producto.term_type.name }}
          </h2>
          <el-row class="center-block text-center">
            <el-col :span="24">
              <el-button @click="myForm.setOferta(true)">
                {{ $t('forms_flujo.oferta_up_solicitud_default.btn_accept') }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-col>
    <el-col
            v-if="myForm.viewDetalle"
            :xl="{span: 12, offset:6 }"
            :lg="{span: 12 ,offset:6}"
            :md="{span: 12 ,offset:6}"
            :sm="{span: 18 ,offset:3}"
            :xs="{span: 18 ,offset:3}"
    >
      <el-col
              class="text-center"
      >

        <label
                class="pz-color-primary pz-font-size-lg"
        >
          <p> <span class="pz-font-size-md pz-color-primary text-center">{{ $t('forms_flujo.oferta_extra_info_default.label_1') }}</span></p>
        </label>
      </el-col>
      <el-col
              :xl="{span: 24, offset:2 }"
              :lg="{span: 24 ,offset:2}"
              :md="{span: 24 ,offset:2}"
              :sm="{span: 24 ,offset:2}"
              :xs="{span: 24 ,offset:2}"
      >
        <label
                class="pz-color-primary pz-font-size-lg el-col el-col-20"
        >
          <p> <span class="pz-font-size-md pz-color-primary text-left el-col el-col-16">{{ $t('forms_flujo.oferta_extra_info_default.label_2') }}</span>
            <span class="pz-font-size-md pz-color-secondary text-right el-col el-col-8">{{ myForm.detalle.capital|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}</span></p>

          <p> <span class="pz-font-size-md pz-color-primary text-left el-col el-col-16">{{ $t('forms_flujo.oferta_extra_info_default.label_3') }}</span>
            <span class="pz-font-size-md pz-color-secondary text-right  el-col el-col-8">{{ myForm.detalle.interes|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}</span></p>

          <p> <span class="pz-font-size-md pz-color-primary text-left el-col el-col-16">{{ $t('forms_flujo.oferta_extra_info_default.label_4') }}</span>
            <span class="pz-font-size-md pz-color-secondary text-right  el-col el-col-8">  {{ myForm.detalle.iva|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}</span></p>

          <p> <span class="pz-font-size-md pz-color-primary text-left el-col el-col-16">{{ $t('forms_flujo.oferta_extra_info_default.label_5') }}</span>
            <span class="pz-font-size-md pz-color-secondary text-right el-col el-col-8">{{ myForm.detalle.total|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}</span></p>

          <p> <span class="pz-font-size-md pz-color-primary text-left el-col el-col-16">{{ $t('forms_flujo.oferta_extra_info_default.label_6') }}</span>
            <span class="pz-font-size-md pz-color-secondary text-right el-col el-col-8">{{ myForm.detalle.monto_nuevo|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}</span></p>

          <p> <span class="pz-font-size-md pz-color-primary text-left el-col el-col-16">{{ $t('forms_flujo.oferta_extra_info_default.label_7') }}</span>
            <span class="pz-font-size-md pz-color-secondary text-right el-col el-col-8">{{ myForm.detalle.monto_dispersar|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}</span></p>

        </label>
      </el-col>
    </el-col>
  </el-row>
</template>
<script>
    export default {
        name: 'PzOfertaUpSolicitudUy',
        inject: ['myForm'],
        mounted() {
            // this.$store.dispatch('loader/down', {trigger: this.$options.name})
          this.$store.dispatch('loader/up', {trigger: this.$options.name})
        }
    }
</script>

