<template>
  <pz-form-flujo
    v-model="formularioProducts['captcha']"
    :forward="handleSave"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.select_products_all.title') }}
    </span>
    <el-col
      slot="formulario"
      :xl="{span: 8, offset:8}"
      :lg="{span: 10, offset:7}"
      :md="{span: 12, offset:6}"
      :sm="{span: 24}"
      :xs="{span: 24}"
    >
      <el-form
        ref="formularioProducts"
        :model="formularioProducts"
        status-icon
        :rules="rules_formularioProducts"
      >
        <el-form-item
          prop="selected"
          :label="$t('forms_flujo.select_products_all.products')"
          :error="errors.get('selected')"
        >
          <el-select
            v-model="formularioProducts.selected"
            clearable
            :placeholder="$t('forms_flujo.select_products_all.products')"
            @blur="errors.clear('selected')"
          >
            <el-option
              v-for="producto in productos"
              :key="producto.code_string"
              :label="producto.nombre_comercial "
              :value="producto.code_string"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </el-col>
  </pz-form-flujo>
</template>
<script>
    import baseProductsAll from "./baseProductsAll";
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";

    export default {
        name: 'PzProductsAllSelect',
        components: {PzFormFlujo},
        mixins: [baseProductsAll],
        data() {
            return {}
        },
        methods: {},
    }
</script>


